//import Vue from 'vue';
import "./css/main.scss";
// Import Swiper and modules
import Swiper, { Navigation, Pagination } from 'swiper';
// Install modules
Swiper.use([Navigation, Pagination]);

// First we get the viewport height and we multiple it by 1% to get a value for a vh unit
let vh = window.innerHeight * 0.01;
// Then we set the value in the --vh custom property to the root of the document
document.documentElement.style.setProperty('--vh', `${vh}px`);


// Can be used with JavaScript
// -----------------------------
const icnMenu = document.querySelector('.menu-icon');
const mobileMenu = document.querySelector('.mobileMenu');
icnMenu.addEventListener('click', () => {
	mobileMenu.classList.toggle('active');
});
